.bestellingblok{
    float: left;
    background: rgba($darkblue, 0.7);
    width: calc(25% - 1em);
    margin: 0.5em;
    padding: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    &:hover{
        background: $darkblue;
    }
    p{
        margin-bottom: 0;
    }
}

ul.pagination{
    text-align: center;
    list-style: none;
    margin-top: 2em;
    li{
        display: inline-block;
        border: 1px solid $grey;
        width: 30px;
        height: 30px;
        font-size: 1.2em;
        color: $darkblue;
        transition: 0.3s all;
        position: relative;
        &:hover{
            color: white;
            background: $lightblue;
        }
        a, span{
            transition: none;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        &.active{
            background: $lightblue;
            color: white;
        }
        &.disabled{
            background: $grey;
            opacity: 0.4;
            &:hover{
                color: $darkblue;
            }
        }
    }
}

.re-order{
    background: $green;
    display: inline-block;
    color: white;

    padding: 5px 10px;
}

.bestelnummerblok{
    background: $lightblue;
    margin-top: 2em;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-bottom: 1em;
    p{
        margin-bottom: 0;
        width: 50%;
    }
    .bestellingnummer{
        text-align: left;
    }
    .total{
        text-align: right;
        font-size: 1.3em;
    }
}

.bestellingnummerleftblok{
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(255,255,255,0.3);
    padding: 10px 0;
    transition: 0.3s all;
    &:hover{
        background: lighten($darkblue, 10);
    }

    p{
        padding: 0.3em;
        margin: 0;
        width: 50%;
        &:first-child{
            text-align: left;
        }
        &:last-child{
            text-align: right;
            font-size: 1.1em;
        }
    }
}

@media screen and(max-width: 1130px){
    .bestellingblok{
        width: calc(33.33% - 1em);
    }
}

@media screen and(max-width: 900px){
    .bestellingblok{
        width: calc(50% - 1em);
    }
}

@media screen and(max-width: 600px){
    .bestellingblok{
        width: calc(100% - 1em);
    }
}
.toestemming {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 0.9em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.toestemming input {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.toestemming .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    border: 2px solid white;
}
.toestemming .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 3px;
    top: 1px;
    width: 3px;
    height: 5px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.toestemming input:checked ~ .checkmark {
    background-color: $lightblue;
}
.toestemming input:checked ~ .checkmark:after {
    display: block;
}
