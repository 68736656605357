.wrapperlogin{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo{
        display: block;
        background: white;
        padding: 1em;
        max-height: 140px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 270px;
        z-index: 100;
        img{
            max-width: 100%;
        }
    }
    .layer{
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0,0,0,0.2);
        position: absolute;
    }
    .language{
        position: absolute;
        right: 20px;
        top: 20px;
        color: white;
        font-size: 1.2em;
        .active{
            opacity: 0.5;
        }
        &:hover a{
            opacity: 0.5;
        }
        span{
            padding: 0 0.2em;
        }
    }
    .content{
        max-width: 600px;
        background: rgba(0,0,0,0.4);
        z-index: 100;
        padding: 1em;
        h2{
            text-align: center;
            color: white;
            font-size: 5em;
            display: block;
            margin-bottom: 0;
            font-family: 'Rockness';
            text-transform: none;
        }
        p{
            color: white;
            text-align: center;
        }
        form{
            margin-top: 2em;
            fieldset{
                border: none;
                float: left;
                margin: 0 1em 1em 1em;
                width: calc(50% - 2em);
                text-align: center;
                &.full{
                    width: calc(100% - 2em);
                }
                input{
                    outline: none;
                    appearance: none;
                    border: 2px solid white;
                    background: rgba(255,255,255,0);
                    width: 100%;
                    font-size: 1em;
                    padding: 0.7em;
                    text-align: center;
                    color: white;
                    border-radius: 40px;
                }
                input.send{
                    width: 50%;
                    text-align: center;
                    border: 2px solid $darkblue;
                    background: $darkblue;
                    color: white;
                    cursor: pointer;
                    transition: 0.3s all;
                    &:hover{
                        background: $lightblue;
                        border: 2px solid $lightblue;
                    }
                }
            }
        }
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: white;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: white;
    }
    :-ms-input-placeholder { /* IE 10+ */
          color: white;
    }
    :-moz-placeholder { /* Firefox 18- */
          color: white;
    }
}

footer#footerLogin{
    background: $darkblue;
    color: white;
    text-align: center;
    padding: 1em 0;
    margin: 0;
    width: 100%;
    p{
        margin-bottom: 0;
    }
}

@media screen and(max-width: 650px){
    .wrapperlogin{
        .content{
            max-width: 90%;
        }
    }
}

@media screen and(max-width: 500px){
    .wrapperlogin{
        .logo{
            left: 5%;
            transform: translateX(0%);
        }
        .content{
            form{
                fieldset{
                    width: calc(100% - 2em);
                    input.send{
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and(max-width: 400px){
    .wrapperlogin{
        .logo{
            left: 5%;
            width: 200px;
            transform: translateX(0%);
        }
    }
}

.activate{
    label{
        color: white;
    }


    .send {
        text-align: center;
        margin-top: 20px;
        width: calc(100% - 1em) !important;

        input[type="submit"]{
            display: block;
        }
    }

}
