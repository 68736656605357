header{
    width: 97%;
    padding: 1em 0;
    margin: auto;
    .logo{
        max-width: 200px;
        float: left;
        margin-bottom: 1em;
        position: relative;
        z-index: 100000;
    }
    nav{
        position: relative;
        margin-top: 1em;
        z-index: 1000;
        ul{
            float: right;
            display: block;
            list-style: none;
            li{
                float: left;
                position: relative;
                i{
                    margin-right: 0.5em;
                }
                &.language{
                    a:hover, a.active{
                        background: none;
                        color: $darkblue;
                    }
                    a:first-child{
                        padding: 0.5em 0.5em 0.5em 2em;
                    }
                    a:last-child{
                        padding: 0.5em 2em 0.5em 0.5em;
                        &:after{
                            left: auto;
                            right: 0;
                        }
                    }
                }
                a{
                    font-size: 1.1em;
                    padding: 0.5em 2em;
                    text-transform: uppercase;
                    position: relative;
                    display: inline-block;
                    &:hover, &.active{
                        background: $darkblue;
                        color: white;
                    }
                    &:after{
                        background: rgba(0,0,0,0.2);
                        width: 1px;
                        height: 100%;
                        top: 0;
                        left: 0;
                        position: absolute;
                        content: '';
                        display: block;
                    }
                }
                &:hover{
                    ul{
                        display: block;
                    }
                }
                &.submenu{
                    & > a{
                        padding: 0.5em 1.5em 0.5em 2em;
                    }
                    i{
                        position: relative;
                        top: -3px;
                        margin-left: 5px;
                    }
                }
                ul{
                    transition: 0.3s all;
                    display: none;
                    position: absolute;
                    padding: 0.8em 0;
                    min-width: 440px;
                    li{
                        float: left;
                        width: 50%;
                        display: block;
                        background: $darkblue;
                        a{
                            padding: 0.25em 0.7em;
                            display: block;
                            color: white;
                            &:hover{
                                background: $darkblue;
                            }
                            &:after{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

#clearing{
    display: none;
}
#trigger{
    display: none;
    float: right;
    width: 20px;
    height: 15px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    margin-left: 50px;
    color: $darkblue;
    font-size: 1.5em;
    margin-top: 0.6em;
    .tekst{
        position: absolute;
        left: -50px;
        text-transform: uppercase;
        font-size: 0.8em;
        top: -5px;
        margin-left: -1em;
        font-weight: 100;
  }
  span{
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      margin-top: 1px;
      background: $darkblue;
  }
  span:nth-child(2) {
      top: 0px;
  }
  span:nth-child(3), span:nth-child(4) {
      top: 5px;
  }
  span:nth-child(5) {
      top: 10px;
  }
}
#trigger.open{
    span:nth-child(2) {
        top: 18px;
        width: 0%;
        left: 50%;
    }
    span:nth-child(3) {
        transform: rotate(45deg);
    }
    span:nth-child(4) {
        transform: rotate(-45deg);
    }
    span:nth-child(5) {
        top: 18px;
        width: 0%;
        left: 50%;
    }
}

@media screen and (min-width:1535px){
    nav{
        ul.menu{
            display: block !important;
        }
    }
}

@media screen and (max-width: 1535px){
    #clearing{
        display: block;
    }
    #trigger{
        display: block;
    }
    nav{
        ul.menu{
            display: none;
            margin-top: 1em;
            width: 100%;
            float: none;
            li{
                text-align: center;
                width: 100%;
                float: none;
                a{
                    width: 100%;
                    &:after{
                        display: none;
                    }
                }
                &.language{
                    width: auto;
                    a{
                        display: inline-block;
                        width: auto;
                    }
                }
                &.submenu{
                    position: relative;
                    &:hover{
                        ul{
                            display: none;
                            float: none;
                        }
                    }
                    ul{
                        position: static;
                        width: 100%;
                        padding: 0;
                        min-width: auto;
                        z-index: 100;
                        transition: none;
                        li{
                            background: transparent;
                            width: 100%;
                            float: none;
                            a{
                                display: inline-block;
                                color: $darkblue;
                                font-size: 0.8em;
                            }
                        }
                    }
                }

            }
        }
    }
}


@media screen and (max-width: 700px){
    .slick.big{
        display:none;
    }
    .slick.responsive{
        display:block !important;
    }
}
