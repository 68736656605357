a.back{
    color: $lightblue;
    font-size: 1.1em;
    margin-bottom: 1em;
    display: block;
}

.singleproductgrid{
    border: 2px solid $grey;
    padding: 50px 80px;
    position: relative;
    .left{
        width: 45%;
        float: left;
        text-align: center;

        img{
            max-height: 500px;
        }
    }
    .right{
        width: 45%;
        margin-right: 5%;
        float: right;
    }

    h2{
        margin-bottom: 0.2em !important;
        display: inline-block;
    }
    h3{
        margin-top: 0 !important;
        margin-bottom: 2em !important;
    }
    .favo{
        font-size: 2em;
        position: absolute;
        right: 0;
        top: 20px;
    }

    .prijs{
        width: 100%;
        text-align: right;
        color: $green;
        font-size: 2em;
        margin-bottom: 1em;
        p{
            margin-bottom: 0;
            display: inline-block;
        }
        span{
            color: $fontColor;
            text-decoration: none;
            font-size: 0.6em;
            margin-right: 0.5em;
        }
    }

    form{
        width: 100%;
        .title{
            width: 160px;
            text-align: center;
            margin-bottom: 2px;
        }
        .left{
            width: 80px;
            float: left;
        }
        .right{
            width: 80px;
            float: left;
            margin-right: 0;
        }
        label{
            width: 100%;
            text-align: center;
            display: block;
        }
        input{
            width: 100%;
            border: 1px solid $lightblue;
            font-size: 1em;
            padding: 0.5em;
            text-align: center;
            outline: none;

            &:disabled{
                background: $grey;
                color: darken($grey, 20);
                border: 1px solid $grey;
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
        button:disabled, button.green:disabled {
            background: $grey;
            color: darken($grey, 20);
            border: 1px solid $grey;
            cursor: default;
            &:hover{
                background: $grey;
                border: 1px solid $grey;
            }


        }
        button{
            width: calc(100% - 160px);
            float: right;
            background: $lightblue;
            border: none;
            color: white;
            padding: 0.5em 0;
            font-size: 1em;
            border: 1px solid $lightblue;
            outline: none;
            transition: 0.3s all;
            cursor: pointer;
            margin-top: 18px;
            i{
                margin-right: 0.5em;
            }
            &:hover{
                background: $darkblue;
                border: 1px solid $darkblue;
            }

            &.green{
                background-color: $green;
                border: 1px solid $green;
            }
        }
    }
}


@media screen and(max-width: 991px){
    .singleproductgrid{
        .left{
            width: 100%;
        }
        .right{
            width: 100%;
            margin-right: 0%;
        }
    }
}

@media screen and(max-width: 650px){
    .singleproductgrid {
        padding: 2em;
        .favo {
            right: 1em;
        }
    }
}

@media screen and(max-width: 500px){
    .singleproductgrid {
        padding: 2em;
        .favo {
            right: 1em;
        }
        form {
            .title {
                width: 100%;
            }
            .left, .right{
                width: 50%;
            }
            button{
                width: 100%;
                margin-top: 0;
            }
        }
    }
}
