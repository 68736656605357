.searchform{
    input{
        appearance: none;
        border: 1px solid $grey;
        padding: 10px;
        font-size: 1em;
        width: calc(100% - 150px);
        margin-bottom: 1em;
        outline: none;
        float: left;
    }
    input[type="submit"]{
        width: 150px;
        background: $lightblue;
        border: none;
        color: white;
        transition: 0.3s all;
        border: 1px solid $lightblue;
        cursor: pointer;
        &:hover{
            border: 1px solid $darkblue;
            background: $darkblue;
        }
    }
}
.searchform.search-products{
    max-width: 500px;
    float: right;
    input{
        padding: 5px 10px;
        width: calc(100% - 80px);
    }
    input[type="submit"]{
        width: 80px;
        font-family:"fieldwork";
        font-size: 1em;
        padding: 1px 5px 2px 5px;
    }
}

.zoekopdracht{
    margin: 20px auto 20px auto;
    text-align: center;
    h3{
        margin: 0 !important;
    }
}

#filterform{
    max-width: 200px;
    float: right;
    width: 100%;
}
.winkelmandjeform, #filterform{
    label{
        width: 100%;
        margin-bottom: 0.2em;
        display: block;
    }
    select{
        width: 100%;
        appearance: none;
        border: 1px solid $grey;
        padding: 7px;
        font-size: 0.9em;
        margin-right: 10px;
        outline: none;
        background-color: transparent;
        background: url(/images/downarrow.png);
        background-repeat: no-repeat;
        background-size: 9px;
        background-position: 98% center;
        border-radius: none;
    }
    .select2-selection{
        width: 100%;
        appearance: none;
        border: 1px solid $grey;
        padding: 7px;
        font-size: 0.9em;
        margin-right: 10px;
        outline: none;
        background-color: transparent;
        background: url(/images/downarrow.png);
        background-repeat: no-repeat;
        background-size: 9px;
        background-position: 98% center;
        border-radius: none;
        height: auto;

        .select2-selection__rendered {
            line-height: normal;
        }
        .select2-selection__arrow{
            display: none;
        }
    }
    #klantid{
        .select2-selection{
            background-color: $white;
        }
    }
    input, textarea{
        appearance: none;
        border: 1px solid $grey;
        padding: 7px;
        font-size: 0.9em;
        width: 100%;
        outline: none;
    }
    fieldset{
        float: left;
        width: calc(50% - 0.5em);
        margin: 0.5em;
        border: none;
        &:nth-child(2n){
            margin-left: 0;
        }
        &:nth-child(2n+1){
            margin-right: 0;
        }
        &.full{
            width: 100%;
            margin: 0.5em 0em;
        }
    }
}
#filterprod{
    text-align: center;
    background-color: rgba($grey, 0.2);
    padding: 10px 10px 15px;
    label{
        margin-bottom: 0.2em;
        color: $lightblue;
        font-weight: bold;
        display: block;
        text-align: left;
        font-size: 1.1em;
        padding-left: 3px;
    }
    select, .select2-selection{
        text-align: left;
        appearance: none;
        border: 1px solid $grey;
        padding: 7px 17px;
        font-size: 0.9em;
        outline: none;
        background-color: transparent;
        background: url(/images/downarrow.png);
        background-repeat: no-repeat;
        background-size: 9px;
        background-position: calc(100% - 10px) center;
        border-radius: none;
        height: auto;
        text-align: left;
        background-color: $white;
        width: 100%;

        .select2-selection__rendered {
            line-height: normal;
            padding: 0;
        }
        .select2-selection__arrow{
            display: none;
        }
    }
    .select2-container{
        width: 100% !important;
    }
    input{
        margin-right: 10px;
    }
    fieldset{
        margin: 0.5em 1em;
        border: none;
        display: inline-block;
        width: calc(19% - 2em);
    }
}

.accountgegevens{
    label{
        width: 100%;
        margin-bottom: 0.2em;
        display: block;
    }
    input{
        appearance: none;
        border: 1px solid $grey;
        padding: 7px;
        font-size: 0.9em;
        width: 100%;
        outline: none;
    }
    input:disabled{
        background-color: $grey;
        cursor: not-allowed;
    }
    input[type="submit"]{
        width: 150px;
        background: $lightblue;
        border: none;
        color: white;
        transition: 0.3s all;
        cursor: pointer;
        margin: 10px 0;
        &:hover{
            background: $darkblue;
        }
    }
    fieldset{
        float: left;
        width: calc(50% - 1em);
        margin: 0.5em;
        border: none;
        &:nth-child(2n){
            margin-left: 0.5em;
        }
        &:nth-child(2n+1){
            margin-right: 0.5em;
        }
    }
    p{
        margin: 0.5em;
    }
}

.filter-products{
    display: block;
    margin-top: 20px;
    select{
        appearance: none;
        border: 1px solid $grey;
        padding: 7px;
        font-size: 0.9em;
        width: calc(100% - 150px - 14px - 10px);
        margin-right: 10px;
        outline: none;
        background-color: transparent;
        background: url(/images/downarrow.png);
        background-repeat: no-repeat;
        background-size: 9px;
        background-position: 98% center;
        border-radius: none;
    }
    input[type="submit"]{
        width: 150px;
        background: $lightblue;
        border: none;
        color: white;
        transition: 0.3s all;
        cursor: pointer;
        margin: 10px 0;
        padding: 8px;
        font-size: 0.8em;
        text-transform: uppercase;
        border: 1px solid $lightblue;
        border-radius: 4px;
        &:hover{
            border: 1px solid $darkblue;
            background: $darkblue;
        }
    }
}

@media screen and(max-width: 400px){
    .winkelmandjeform{
        fieldset{
            float: none;
            width: 100%;
            margin: 0 0 10px 0 !important;
        }
    }
    .filter-products{
        select{
            width: 100%;
            margin: 0;
            float: none;
        }
        input[type="submit"]{
            float: right;
        }
    }
}


.user-table{
    table {
        border-collapse: collapse;
        width: 100%;
    }

    td, th {
        border: 1px solid $grey;
        text-align: left;
        padding: 8px;
    }

    th{
        font-weight: bold;
        text-transform: uppercase;
    }

    tr:nth-child(even) {
        background-color: $grey;
    }

    i{
        margin-left: 10px;
    }
}



@media screen and(max-width: 1200px){
    #filterprod{
        fieldset{
            width: calc(32.333% - 2em);
        }
    }
}
@media screen and(max-width: 768px){
    #filterprod{
        text-align: left;
        fieldset{
            width: calc(49% - 2em);
        }
    }
}
@media screen and(max-width: 550px){
    #filterprod{
        fieldset{
            width: calc(99% - 2em);
        }
    }

    .searchform.search-products{
        max-width: none;
        width: 100%;
        float: left;
        margin-top: 2em;
        input{
            padding: 5px 10px;
            width: calc(100% - 80px);
        }
        input[type="submit"]{
            width: 80px;
            font-family:"fieldwork";
            font-size: 1em;
        }
    }
}
