.headerImage{
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 350px;
}
.intro{
    max-width: 650px;
    text-align: center;
    margin: auto;
}
#category{
    h2{
        margin-top: 1em;
        margin-bottom: 0.5em;
    }
    ul{
        margin: 2em 0;
        list-style: none;
        display: block;
        li{
            float: left;
            margin: 0.5em;
            width: calc(20% - 1em);
            a{
                padding: 2.5em 1em;
                display: block;
                background: $grey;
                text-align: center;
                text-transform: uppercase;
                cursor: pointer;
                &:hover{
                    background: $lightblue;
                    color: white;
                }
            }
        }
    }
}

#inDeKijker{
    margin-bottom: 2em;
    .productgrid{
        list-style: none;
        display: block;
        text-align: center;
        & > li{
            margin: 1em 2em;
            width: calc(25% - 4em);
            float: left;
            position: relative;

            & > a.single{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
            .favo{
                position: relative;
                z-index: 999;
            }
            .image{
                width: 200px;
                height: 150px;
                margin: auto;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                margin-bottom: 10px;
            }
            h4{
                font-size: 1.2em;
            }
            p.prijs{
                color: $green;
                font-size: 1.1em;
                .oldprice{
                    color: $darkblue;
                    text-decoration: line-through;
                    margin-right: 1em;
                }
            }
            form{
                width: 100%;
                display: block;
                position: relative;
                z-index: 999;

                input{
                    width: calc(100% - 50px);
                    float: left;
                    border: 1px solid $lightblue;
                    font-size: 1em;
                    padding: 0.5em;
                    text-align: center;
                    outline: none;
                }
                button:disabled {
                    background: $grey;
                    color: darken($grey, 20);
                    border: 1px solid $grey;
                    cursor: default;
                    &:hover{
                        background: $grey;
                        border: 1px solid $grey;
                    }
                }
                button{
                    width: 50px;
                    float: right;
                    background: $lightblue;
                    border: none;
                    color: white;
                    padding: 0.5em 0;
                    font-size: 1em;
                    border: 1px solid $lightblue;
                    outline: none;
                    transition: 0.3s all;
                    cursor: pointer;
                    &:hover{
                        background: $darkblue;
                        border: 1px solid $darkblue;
                    }
                }
            }
        }
    }
}
.extra-info{
    position: relative;
    display: inline-block;
    .icon{
        display: inline-block;
        font-size: 0.7em;
        float: right;
        cursor: pointer;
    }
    .content{
        position: absolute;
        width: 250px;
        background-color: $white;
        right: 0;
        top: 20px;
        padding: 1em 1em;
        font-size: 0.7em;
        border: 2px solid $darkblue;
        display: none;
        text-align: center;
        z-index: 9999;

        img{
            float: right;
            padding-left: 10px;
            margin-top: -10px;
            margin-right: -10px;
        }
        h4{
            font-size: 1.2em;
            position: relative;
            height: auto !important;
        }
        .cat{
            color: $lightblue;
            margin-bottom: 0.5em;
        }
        ul.extra{
            list-style-type: none;
            text-align: center;
            li{
                display: inline-block;
                position: relative;
                padding: 0px 10px;
                &:after{
                    content: '|';
                    position: absolute;
                    top: 0;
                    right:-3px;
                }
                &:last-child:after{
                    content: '';
                }
                &:first-child{
                    padding-left: 0px;
                }
                &:last-child{
                    padding-right: 0px;
                }
            }
        }
        p{
            margin-bottom: 0;
        }
    }

    &:hover{
        .content{
            display: block;
        }
    }
}

@media screen and(max-width: 1150px){
    header .headerImage{
        height: 300px;
    }
    #category{
        ul{
            li{
                width: calc(25% - 1em);
            }
        }
    }
    #inDeKijker{
        .productgrid{
            li{
                width: calc(50% - 4em);
            }
        }
    }
}

@media screen and(max-width: 950px){
    header .headerImage{
        height: 250px;
    }
    #category{
        ul{
            li{
                width: calc(33.33% - 1em);
            }
        }
    }
}

@media screen and(max-width: 750px){
    header .headerImage{
        height: 200px;
    }
    #category{
        ul{
            li{
                width: calc(50% - 1em);
            }
        }
    }
}

@media screen and(max-width: 600px){
    header .headerImage{
        height: 180px;
    }
    #inDeKijker{
        .productgrid{
            li{
                width: calc(100% - 4em);
            }
        }
    }
}

@media screen and(max-width: 500px){
    header .headerImage{
        height: 150px;
    }
    #category{
        ul{
            li{
                width: calc(100% - 1em);
            }
        }
    }
}

.add-btn{
    padding: 5px 10px;
    background: $lightblue;
    border: none;
    color: white;
    font-size: 1em;
    border: 1px solid $lightblue;
    outline: none;
    transition: 0.3s all;
    cursor: pointer;
    &:hover{
        background: $darkblue;
        border: 1px solid $darkblue;
    }
}

.search-button{
    width: 100%;
    border: 2px solid white;
    color: white;
    background-color: $darkblue;
    padding: 0.5em;
    margin-top: 1.5em;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    -webkit-transition: 0.3s all;
    transition: 0.3s all;
    outline: none;
}
