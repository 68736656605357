#resetpassword{
    width: 98%;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.03);
    .box{
        min-width: 400px;
        max-width: 400px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        padding: 2em;
        background: white;

        .panel-heading{
            font-size: 2em;
            color: $lightblue;
            margin-bottom: 1em;
            font-family: 'Rockness';
            text-align: center;
        }
        input{
            appearance: none;
            border: 1px solid $grey;
            padding: 10px;
            font-size: 1em;
            width: 100%;
            margin-bottom: 1em;
            outline: none;
        }
        button{
            width: 100%;
            background: $lightblue;
            border: none;
            color: white;
            padding: 10px;
            font-size: 1em;
            outline: none;
            transition: 0.3s all;
            cursor: pointer;
            appearance: none;

            &:hover{
                background: $darkblue;
            }
        }
        .alert-success{
            color: $green;
            text-align: center;
            margin-bottom: 10px;
        }
        .help-block{
            color: red;
            text-align: center;
            margin-bottom: 10px;
        }
    }
}
