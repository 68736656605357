* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-weight: lighter;
	height: auto;
}

html,
body {
	width: 100%;
	height: 100%;
	font-size: 15px;
	margin: 0;
	padding: 0;
	color: $fontColor;
	font-family: "fieldwork";
	overflow-x: hidden;
	line-height: 1.2;
}

body.login {
	background-image: url(/images/bg.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.select2-container--open .select2-dropdown--below,
.select2-container--open .select2-dropdown--above {
	min-width: 200px;
}

.select2-dropdown {
	border: 1px solid #E7E7E5 !important;
}

.beheerdersKant {
	&>li {
		display: flex !important;

		.info {
			width: 35% !important;
		}

		.prijs {
			width: 40% !important;
			float: left !important;
		}
	}

	.buttonsDeleteEdit {
		float: right;
		width: 20%;
		text-align: right;

		i {
			margin-left: 15px;
		}

		.fa-trash-o {
			color: red;
		}
	}
}

.wrapper {
	min-height: calc(100% - 98px - 2em);
	// overflow-x: hidden;
	overflow: auto;
}

.wrapperlogin {
	min-height: calc(100% - 48px);
	overflow-x: hidden;
}

.inWinkelmandje {
	background: #E0F0D8;
	border: 1px solid #3C763D;
	border-radius: 4px;
	padding: 0.5em;
	margin-bottom: 2em;

	p {
		margin-bottom: 0;
		display: inline-block;
		float: left;
		color: #3C763D;
		padding: 0.5em 0;
	}

	a {
		color: white;
		display: inline-block;
		float: right;
		padding: 0.5em 1em;
		background: #3C763D;
		border-radius: 4px;

		&:hover {
			background: lighten(#3C763D, 15);
		}
	}
}

.gobackto {
	color: $lightblue;
	transform: scale(1);
	transition: all 0.35s;
	display: inline-block;
	margin-bottom: 1em;

	i {
		margin-right: 10px;
	}

	&:hover {
		color: $darkblue;
		transform: scale(1.02);
	}
}

img {
	max-width: 100%;
	height: auto;
	max-height: 100%;
	border: 0;
}

a {
	text-decoration: none;
	outline: none;
	transition: all 0.5s;
	color: inherit;
}

.container {
	width: 100%;
	margin: 0 auto;
	position: relative;
	z-index: 10;
	max-width: 1100px;
}

.containersmall {
	width: 100%;
	margin: 0 auto;
	position: relative;
	z-index: 10;
	max-width: 900px;
}

p {
	margin-bottom: 1em;
}

h2 {
	// font-family: 'Rockness';
	margin-bottom: 20px;
	font-size: 20px;
	color: #25408F;
	text-transform: uppercase;
	font-weight: 700;
}

.clearing {
	clear: both;
	display: block;
}

.recent-besteld-label {
	background: $darkblue;
	color: white;
	padding: 0.75em 1.5em;
	border-radius: 4px;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 1em;
	display: inline-block;
	position: absolute;
	left: 10px;
	top: 10px;
}

// .favorites .recent-besteld-label, #single-product-element .recent-besteld-label {
// 	left: 10px !important;
// 	right: auto !important;
// }

#filterprod {
    .select2-selection {
        width: 175px ! important;
    }
}

#not-favorites-div .recent-bought-list-item {
	cursor: grab;
	.image {
		width: 200px;
		height: 150px;
		margin: auto;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		margin-bottom: 10px;
	}

	p.prijs {
		color: $green;
		font-size: 1.1em;

		.oldprice {
			color: $lightblue;
			text-decoration: line-through;
			margin-right: 1em;
		}
	}

	form {
		width: calc(100% - 50px);
		display: flex;
		justify-content:center;
		margin: auto;
		z-index: 999;

		input {
			// width: calc(100% - 50px);
			// float: left;
			border: 1px solid $lightblue;
			font-size: 1em;
			padding: 0.5em;
			text-align: center;
			outline: none;
			flex-basis: 80%;
		}

		button:disabled {
			background: $grey;
			color: darken($grey, 20);
			border: 1px solid $grey;
			cursor: default;

			&:hover {
				background: $grey;
				border: 1px solid $grey;
			}
		}

		button {
			width: 50px;
			// float: right;
			background: $lightblue;
			border: none;
			color: white;
			padding: 0.5em 0;
			font-size: 1em;
			border: 1px solid $lightblue;
			outline: none;
			transition: 0.3s all;
			cursor: pointer;

			&:hover {
				background: $darkblue;
				border: 1px solid $darkblue;
			}
		}
	}

}

#favorites-banner {
	.slick-prev, .slick-next{
		opacity: 0 !important;
	}
}

.slick-prev, .slick-next{
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	display: block;
	padding: 0;
	transform: translate(0, -50%);
	cursor: pointer;
	color: transparent;
	border: none;
	outline: none;
	background: transparent;
	z-index: 100;
	height: 100%;
	&:before {
		content: '';
		font-size: 30px;
		font-family: FontAwesome;
		color: grey !important;
	}
}
.slick-prev {
	left: 50px;
	&:before{
		content: "\f104";
	}
}
.slick-next {
	right: 50px;
	&:before{
		content: "\f105";
	}
}

.bold {
	font-weight: bold;
}

.op-bestelling-text {
	font-weight: bold;
	color: $darkblue;
	font-size: 12px;
}

.discount-strikethrough {
	text-decoration: line-through;
	font-size: 14px;
	color: black !important;
}

@media screen and(max-width: 1300px) {
	.container {
		width: 95%;
	}
}

@media screen and(max-width: 1300px) {
	.containersmall {
		width: 95%;
	}
}

@media screen and(max-width: 1100px) {
	.slick-prev {
		left: 0px !important;
		margin-top: -10px;
	}
	.slick-next {
		right: 0px !important;
		margin-top: -10px;
	}
}

@media screen and(max-width: 700px) {
	.beheerdersKant {
		&>li {
			display: block !important;

			.info {
				width: 70% !important;
			}

			.prijs {
				margin-left: 10% !important;
				width: 70% !important;
				float: left !important;
				text-align: left !important;
			}
		}

		.buttonsDeleteEdit {
			float: right;
			width: 20%;
			text-align: right;
			position: absolute;
			top: 10px;
			right: 10px;
		}
	}

	#favorites-banner {
		.slick-prev, .slick-next{
			display: none !important;
		}
	}
}

.active-language {
	color: $lightblue;
}