#winkelmandje{
    .logout{
        display: inline-block;
        float: right;
        background-color: $darkblue;
        color: $white;
        margin-bottom: 1em;
        padding: 0.5em 2em;
        font-size: 1.1em;
        transition: 0.3s all;

        &:hover{
            background-color: $lightblue;
        }
    }
    .remove-product{
        position: absolute;
        top: 5px;
        right: 10px;
        font-size: .8em;
        color: darken(red, 10);
        // left: 10px;
    }

    ul.winkemand{
        display: block;
        width: 58%;
        float: left;
        list-style: none;
        li.first{
            width: 100%;
            background: $darkblue;
            color: white;
            font-size: 1.2em;
            padding: 0.5em;
            margin-bottom: 0.6em;
            border: none;
        }
        & > li{
            position: relative;
            border: 2px solid $grey;
            margin-bottom: 0.6em;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1em;

            & > a.single{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
            .content{
                width: 60%;
                padding: 0 1em;
                .image{
                    padding: 0 1em;
                    width: 15%;
                    height: 80px;
                    float: left;
                    margin-right: 1em;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                .info{
                    padding: 0 1em;
                    width: 78%;
                    float: left;
                    h4{
                        font-size: 1.3em;
                        display: inline-block;

                        img{
                            float: right;
                            padding-left: 10px;
                        }
                    }
                    .cat, .eenheidprijs{
                        color: $lightblue;
                        margin-bottom: 0.5em;
                    }
                    ul.extra{
                        list-style-type: none;
                        li{
                            display: inline-block;
                            position: relative;
                            padding: 0px 10px;
                            &:after{
                                content: '|';
                                position: absolute;
                                top: 0;
                                right:0px;
                            }
                            &:last-child:after{
                                content: '';
                            }
                            &:first-child{
                                padding-left: 0px;
                            }
                            &:last-child{
                                padding-right: 0px;
                            }
                        }
                    }
                    p{
                        margin-bottom: 0;
                    }
                }
            }
            form{
                width: 40%;
                padding: 0 1em;
                text-align: right;
                z-index: 999;
                position: relative;
                .prijs{
                    width: 100%;
                    color: $green;
                    font-size: 1.4em;
                }
                .title{
                    width: 160px;
                    text-align: center;
                    margin-bottom: 2px;
                }
                .left{
                    width: 80px;
                    float: left;
                }
                .right{
                    width: 80px;
                    float: left;
                }
                label{
                    width: 100%;
                    text-align: center;
                    display: block;
                    font-size: 0.8em;
                    margin-bottom: 4px;
                }
                input{
                    width: 100%;
                    border: 1px solid $lightblue;
                    font-size: 1em;
                    padding: 0.5em;
                    text-align: center;
                    outline: none;

                    &:disabled{
                        background: $grey;
                        color: darken($grey, 20);
                        border: 1px solid $grey;
                        opacity: 0.5;
                        cursor: not-allowed;
                    }
                }
                input[type="submit"], button{
                    width: calc(100% - 160px);
                    float: right;
                    background: $lightblue;
                    border: none;
                    color: white;
                    padding: 0.5em 0;
                    font-size: 1em;
                    border: 1px solid $lightblue;
                    outline: none;
                    transition: 0.3s all;
                    cursor: pointer;
                    margin-top: 18px;
                    i{
                        margin-right: 0.5em;
                    }
                    &:hover{
                        background: $darkblue;
                        border: 1px solid $darkblue;
                    }

                    &:disabled, &.green:disabled {
                        background: $grey;
                        color: darken($grey, 20);
                        border: 1px solid $grey;
                        cursor: default;
                        &:hover{
                            background: $grey;
                            border: 1px solid $grey;
                        }
                    }
                }
            }
            form.shopcart{
                .left{
                    width: calc(100% - 80px);
                }
                input[type="submit"], button{
                    width: 80px;
                }
            }
        }
    }

    .totaal{
        float: right;
        width: 40%;
        background: rgba($darkblue, 0.7);
        color: white;
        padding: 1.5em;
        h3{
            font-size: 1.4em;
            text-transform: uppercase;
        }
        ul.totaalprijs{
            list-style: none;
            display: block;
            margin-top: 1.5em;
            li{
                float: left;
                width: 50%;
                font-size: 1.7em;
                &:last-child{
                    text-align: right;
                }
            }
        }
        input[type="submit"], button{
            width: 100%;
            border: 2px solid white;
            background: white;
            color: $lightblue;
            padding: 0.5em;
            margin-top: 1.5em;
            text-transform: uppercase;
            font-size: 1em;
            font-weight: bold;
            cursor: pointer;
            transition: 0.3s all;
            outline: none;
            &:hover{
                background: transparent;
                color: white;
            }
        }

        input:disabled{
            background-color: $grey;
            cursor: not-allowed;
        }
    }
}

@media screen and(max-width: 1000px){
    #winkelmandje{
        ul.winkemand{
            &.favorites li form {
                width: 40% !important;
            }
        }
    }
}

@media screen and(max-width: 950px){
    #winkelmandje{
        ul.winkemand{
            width: 100%;
            float: none;
        }
        .totaal{
            float: none;
            width: 100%;
        }
    }
}

@media screen and(max-width: 800px){
    #winkelmandje{
        ul.winkemand, ul.winkemand.favorites{
            li{
                display: block;
                .content{
                    width: 100% !important;
                    display: block;
                    float: left;
                }
                form{
                    width: 100% !important;
                    float: right;

                    .prijs{
                        width: 80% !important;
                        float: right;
                        text-align: left;
                        padding-left: 5px;
                    }
                }
            }
        }
    }
}

@media screen and(max-width: 500px){
    #winkelmandje{
        ul.winkemand, ul.winkemand.favorites{
            li{
                .content{
                    padding: 0;
                    .image{
                        width: 5%;
                    }
                    .info{
                        padding: 0 1em;
                        width: 75%;
                        float: right;
                    }
                }
                form{
                    .prijs{
                        width: 75% !important;
                    }
                }
            }
        }
    }
}

.fa-star-o{
    &:hover{
        color: orange;
    }
}

.fa-star{
    color: orange;
}

.new-price{
    color: $lightblue;
}

.active-promo{
    margin-bottom: 5px;
    a{
        padding-left: 10px;
    }
}

#winkelmandje ul.winkemand.favorites {
    width: 100%;
}

#winkelmandje ul.winkemand.favorites li form{
    width: 30%;
}
