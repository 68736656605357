header .headerImageSmall{
    width: 100%;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    height: 110px;
}

#categorySingle{
    margin: 2em auto 4em auto;
    h3{
        margin-top: 2em;
        margin-bottom: 0.5em;
        text-transform: uppercase;
    }
    .alert-danger {
        background-color: $warningred;
        padding: 10px;
        margin: 10px 0;
        margin-bottom: 2em;
        list-style-type: none;

        &:before {
            font-family:FontAwesome;
            font-style:normal;
            font-weight:400;
            speak:none;
            display:inline-block;
            text-decoration:inherit;
            width:1em;
            margin-right:.2em;
            text-align:center;
            font-variant:normal;
            text-transform:none;
            line-height:1em;
            margin-left:.2em;
            -webkit-font-smoothing:antialiased;
            -moz-osx-font-smoothing:grayscale;
            top: 1.4em !important;
            content:'\f057';
        }
    }


    .euro, .alfa {
        width: 35px;
        height: 35px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        outline: none;
        cursor: pointer;
        border: 1px solid #dedede;
        background-size: 20px;
        // background-color: rgba(0,0,0,0.05);
        float: right;
        margin-bottom: 10px;
    }
    .euro{
        &.up {
            background-image: url(/images/euro_up.png);
        }
        &.down {
            background-image: url(/images/euro_down.png);
        }
    }
    .alfa{
        margin-right: 10px;
        &.up {
            background-image: url(/images/alfa_up.png);
        }
        &.down {
            background-image: url(/images/alfa_down.png);
        }
    }

    ul.productgrid{
        list-style: none;
        display: block;
        & > li{
            width: 100%;
            display: block;
            border: 2px solid $grey;
            padding: 1em;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            position: relative;

            & > a.single{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
            .favo{
                width: 5%;
                padding-right: 20px;
                position: relative;
                z-index: 999;

                &:before{
                    content:"\f08a";
                    font-family: FontAwesome;
                }

                &:hover{
                    color: $favo;

                    &:before{
                        content:"\f004";
                        font-family: FontAwesome;
                    }
                }
            }
            .favo.active{
                color: $favo;

                &:before{
                    content:"\f004";
                    font-family: FontAwesome;
                }
            }
            .image{
                padding: 0 1em;
                width: 5%;
                height: 80px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
            .info{
                padding: 0 1em;
                width: 40%;
                h4{
                    font-size: 1.3em;
                    display: inline-block;

                    img{
                        float: right;
                        padding-left: 10px;
                    }
                }
                .cat{
                    color: $lightblue;
                    margin-bottom: 0.5em;
                }
                ul.extra{
                    list-style-type: none;
                    li{
                        display: inline-block;
                        position: relative;
                        padding: 0px 10px;
                        &:after{
                            content: '|';
                            position: absolute;
                            top: 0;
                            right:0px;
                        }
                        &:last-child:after{
                            content: '';
                        }
                        &:first-child{
                            padding-left: 0px;
                        }
                        &:last-child{
                            padding-right: 0px;
                        }
                    }
                }
                p{
                    margin-bottom: 0;
                }
            }
            .prijs{
                padding: 0 1em;
                width: 15%;
                text-align: center;
                color: $green;
                font-size: 1.4em;
                p{
                    margin-bottom: 0;
                }
                span{
                    color: $lightblue;
                    text-decoration: line-through;
                    font-size: 0.8em;
                    margin-right: 0.5em;
                    display: block;
                }
            }

            form{
                padding: 0 1em;
                width: 35%;
                position: relative;
                z-index: 999;
                .title{
                    width: 160px;
                    text-align: center;
                    margin-bottom: 2px;
                }
                .left{
                    width: 80px;
                    float: left;
                }
                .right{
                    width: 80px;
                    float: left;
                }
                label{
                    width: 100%;
                    text-align: center;
                    display: block;
                    font-size: 0.8em;
                    margin-bottom: 4px;
                }
                input{
                    width: 100%;
                    border: 1px solid $lightblue;
                    font-size: 1em;
                    padding: 0.5em;
                    text-align: center;
                    outline: none;

                    &:disabled{
                        background: $grey;
                        color: darken($grey, 20);
                        border: 1px solid $grey;
                        opacity: 0.5;
                        cursor: not-allowed;
                    }
                }
                button:disabled, button.green:disabled {
                    background: $grey;
                    color: darken($grey, 20);
                    border: 1px solid $grey;
                    cursor: default;
                    &:hover{
                        background: $grey;
                        border: 1px solid $grey;
                    }
                }
                button{
                    width: calc(100% - 160px);
                    float: right;
                    background: $lightblue;
                    border: none;
                    color: white;
                    padding: 0.5em 0;
                    font-size: 1em;
                    border: 1px solid $lightblue;
                    outline: none;
                    transition: 0.3s all;
                    margin-top: 18px;
                    cursor: pointer;
                    i{
                        margin-right: 0.5em;
                    }
                    &:hover{
                        background: $darkblue;
                        border: 1px solid $darkblue;
                    }

                    &.green{
                        background-color: $green;
                        border: 1px solid $green;
                    }
                }
            }
            &.promo{
                form{
                    input{
                        border: 2px solid $green;
                    }
                    button{
                        background: $green;
                        border: 2px solid $green;
                        &:hover{
                            background: darken($green, 15%);
                            border: 2px solid darken($green, 15%);
                        }
                    }
                }
            }
        }
    }
}


.popup{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.2s;

    .layer{
        background-color: rgba(black, 0.3);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .content{
        position: relative;
        z-index: 1;
        background-color: white;
        display: inline-block;
        width: auto;
        min-width: 500px;
        max-height: 80%;
        overflow: scroll;

        .head{
            background-color: $lightblue;
            text-align: center;
            padding: 20px 20px 15px 20px;
            h3{
                font-weight: bold;
                text-transform: uppercase;
                color: $white;
            }
        }

        .body{
            padding: 2rem;
            fieldset{
                border: none;
            }
            input:focus{
                outline: none;
            }
            input[type="text"]{
                appearance: none;
                border: 1px solid $lightblue;
                width: 100%;
                margin-bottom: 20px;
                font-size: 1em;
                padding: 8px 10px 5px 10px;
            }
            input[name="confirm"]{
                text-transform: uppercase;
            }
            input[type="submit"], .submit{
                appearance: none;
                border: none;
                background: $lightblue;
                border-radius: 0;
                text-transform: uppercase;
                color: white;
                padding: 8px 20px 8px 20px;
                float: right;
                transition: 0.3s all;
                cursor: pointer;
                &:hover{
                    background: darken($lightblue, 10);
                }

                &:disabled, &.disabled {
                    opacity: 0.8;
                    background: $grey;
                    cursor: not-allowed;

                    &:hover{
                        background: $grey;
                    }
                }
            }
            fieldset.alignright{
                margin-top: 40px;
                text-align: right;
                a.cancel{
                    text-decoration: underline !important;
                    margin-right: 20px;
                    cursor: pointer;
                    position: relative;
                    top: 8px;
                    &:hover{
                        color: $lightblue;
                    }
                }
            }
        }
    }
}



@media screen and(max-width: 1200px){
    #categorySingle{
        ul.productgrid{
            li{
                display: block;
                .image{
                    width: 5%;
                    float: left;
                }
                .info{
                    width: 50%;
                    float: left;
                    margin-bottom: 0.8em;
                }
                .prijs{
                    width: 45%;
                    float: right;
                    margin-bottom: 0.8em;
                }
                form{
                    width: 45%;
                    float: right;
                    text-align: right;
                }
            }
        }
        ul.ordergrid{
            li{
                display: flex;
            }
        }
    }
}
@media screen and(max-width: 900px){
    #categorySingle{
        ul.productgrid{
            li{
                form{
                    .left {
                        width: 50%;
                    }
                    .right {
                        width: 50%;
                    }
                    button{
                        width: 100%;
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
@media screen and(max-width: 760px){
    #categorySingle{
        ul.productgrid{
            li{
                display: block;
                .image{
                    width: 10%;
                }
                .info{
                    width: 90%;
                    margin-bottom: 1em;
                }
                .prijs{
                    width: 90%;
                    text-align: left;
                    margin-bottom: 0.8em;
                }
                form{
                    width: 90%;
                    .left {
                        width: 80px;
                    }
                    .right {
                        width: 80px;
                    }
                    button{
                        width: calc(100% - 160px);
                        margin-top: 18px;
                    }
                }
            }
        }
    }
}
@media screen and(max-width: 500px){
    #categorySingle{
        ul.productgrid{
            li{
                form{
                    .title{
                        width: 100%;
                    }
                    .left {
                        width: 50%;
                    }
                    .right {
                        width: 50%;
                    }
                    button{
                        width: 100%;
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

.weight{
    font-size: .8rem;
    color: black;
}

.cats{
    ul{
        list-style: none;

        li{
            border: 1px solid #E7E7E5;
            padding: 5px;

            h4{
                display: inline-block;
                font-size: 1.1em;
                font-weight:bold;
            }

            .controls{
                float: right;

                i{
                    margin-left: 20px;
                }

                .fa-trash-o{
                    color: darken(red, 10);
                }

                .fa-pencil{
                    color: darken(gray, 5);
                }

                .fa-plus-circle{
                    font-size: .8em;
                    color: $darkblue;
                }
            }

            &:nth-child(odd){
                background-color: #E7E7E5;
            }


            ul{
                list-style: disc;
                margin-left: 25px;

                li{
                    padding-left: 0;
                    border: none;

                    background-color: transparent !important;

                    &:hover .sub-controls{
                        display: inline-block;
                    }

                    i{
                        margin-left: 7px;
                        font-size: .9em;
                    }

                    .fa-trash-o{
                        color: darken(red, 10);
                    }

                    .fa-pencil{
                        color: darken(gray, 5);
                    }
                }
            }
        }

        .sub-controls{
            display: none;
        }

        ul{

        }
    }
}

.prijs span{
    color: $lightblue;
    text-decoration: line-through;
    font-size: 0.8em;
    margin-right: 0.5em;

    &.small{
        text-decoration: none;
    }
}


.favo{
    width: 5%;
    padding-right: 20px;
    position: relative;
    z-index: 999;

    &:before{
        content:"\f08a";
        font-family: FontAwesome;
    }

    &:hover{
        color: $favo;

        &:before{
            content:"\f004";
            font-family: FontAwesome;
        }
    }
}
.favo.active{
    color: $favo;

    &:before{
        content:"\f004";
        font-family: FontAwesome;
    }
}
