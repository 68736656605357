footer{
    background: $darkblue;
    text-align: center;
    padding: 1em;
    color: white;
    width: 98%;
    margin: 1em auto;
    h3{
        font-family: 'Rockness';
        font-size: 2em;
    }
    p{
        margin-bottom: 0.5em;
    }
}
