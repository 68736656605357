// Base colors
$fontColor: #1A0200;
$white: #FFFFFF;
$black: #000000;

$darkblue: #25408F;
$lightblue: #1A0200;

$green: #66CC33;
$grey: #E7E7E5;

$favo: #ED4956;


$warningblue: #BDE5F8;
$warninggreen: #DFF2BF;
$warningyellow: #FEEFB3;
$warningred: #FFD2D2;
